<template>
    <Introduction :url="''" :class="'introduction-clcf'">  
        <template #title>
            Clcf
        </template>
        <template #speciality>
            киношкола
        </template>
        <template #min-duration>
            От 1 года
        </template>
        <template #cost>
            8.250 евро/год 
        </template>
        <template #campus>
            Париж
        </template>
    </Introduction>  
</template>
<script>
import Introduction from '@/components/schools/slots/Introduction.vue'
export default {
    components:{
        Introduction
    }
}
</script>