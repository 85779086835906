<template>
    <SectionsNav>
        <template #introduction>
            <a class="nav-link" @click="showSection('presentation')">Обзор</a>
        </template>
        <template #programs>
            <a class="nav-link" @click="showSection('programs')">Программы</a>
        </template>
        <template #campuses>
            <a class="nav-link" @click="showSection('campus')">Кампусы</a>
        </template>
        <template #selectSection>
            <select id="select-mobile-menu" class="form-select form-select-lg" v-model="this.currentSection">
                <option value="presentation">Обзор</option>
                <option value="programs">Программы</option>
                <option value="campus">Кампусы</option>
            </select>
        </template>
    </SectionsNav>
    
    <Obzor v-if="currentSection === 'presentation'" ref="presentation" class="presentation">
        <template #title>
            Обзор
        </template>
        <template #description> 
            Школа CLCF с 1963 года является киношколой, гордящейся успехами своих бывших учеников на ступенях Каннского кинофестиваля.  Это старейшая частная киношкола, расположенная в самом сердце Парижа. Вам доступны специализации: помощник режиссера, сценарист, редактор, монтажер-монтажник и руководитель производства.
            <br>
            Более 6000 учащихся школы получили награды на французских и международных фестивалях. Учебное заведение насчитывает 500 партнерских компаний в 
            аудиовизуальном и кинематографическом секторе. 
            <br>
            Учеба на практике
            <br>
            Вы будете участвовать в создании десятка фильмов в течение своего курса. 
            Менеджер по производству, сценарист, режиссер, редактор, оператор ... на каждом этапе вашего ученичества и под наблюдением профессионалов каждый из вас будет по очереди занимать различные должности в съемочной группе. Это позволяет вам на практике открыть для себя различные профессии, связанные с созданием фильма.
            <br>
            В последний год студенты всех учебных курсов объединяют свои навыки вокруг кинопроекта, который они должны выполнить самостоятельно. Вы учитесь работать в команде на профессиональном уровне.
            </template>
        <template #orientation>
            Bachelor
        </template>
        <template #youtube>
            <iframe width="450" height="315" src="https://www.youtube.com/watch?v=hTQtGFwOk6U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </template>
        <template #speciality>
            киношкола
        </template>
    </Obzor>


    <section v-if="currentSection === 'programs'" ref="programs" class="programs">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 title-main">
                            <h1>Программы</h1>
                        </div>
                    </div>
                    
                    <div class="row program-select-wrapper">
                        <div class="col-12 col-lg-4">
                            <select v-model="program" ref="programSelector" class="form-select form-select-lg">
                                <option disabled value="">Выбрать программу</option>
                                <option value="bk">Бакалавриат в киноискусстве</option>
                                </select>
                        </div>
                    </div>
                    
                    <Program :url="''" v-if="this.program === 'bk'" id="bk">
                        <template #title>
                            Бакалавриат в киноискусстве
                        </template>
                        <template #description>
                            <p>
                                Первый год позволяет вам познакомиться с кинокультурой через ее историю, фильмы, методы, инструменты и собственный язык. В течение года вы узнаете о различных этапах создания фильма, от написания сценария до его показа на большом экране. 
                            </p>
                            <p>
                                Философия второго года обучения в CLCF заключается в приобретении на курсах художественной культуры, необходимой для создания любого фильма. Практические занятия позволяют освоить кинематографические приемы. Вы применяете их через производство индивидуальных и коллективных фильмов в течение года.
                            </p>
                            <p>
                                3-й курс: специализация и сертификация навыков.  Выбирайте одну из  6 специализаций и снимайте более 10 фильмов в течение этого года (оператор, сценарист, помощник режиссера, монтажер и т.д.)
                            </p>
                            <p>
                                4-й год: реализация: Последний год обучения позволяет освоить профессию режиссера. Развивайте свои творческие способности и углубляйте свои технические знания.
                            </p>
                        </template>
                        <template #duree>
                            1-4 года
                        </template>
                        <template #language>
                            французский 
                        </template>
                        <template #cost>
                            8.250 евро - 10.000 евро. 
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #requirements>
                            французский уровня B2, среднее образование, досье.
                        </template>
                        <template #start-time>
                            сентябрь 
                        </template>
                    </Program>

                </div>
            </div>
        </div>
    </section>

    <CampusMain v-if="currentSection === 'campus'" ref="campus" class="campus">
        <template #campuses>
            <Campus>
                <template #localisation>
                    Париж
                </template>
                <template #description>
                    4000 м² предназначены для обучения профессиям кино! CLCF, расположенный в самом сердце района Музаи в 19-м округе Парижа, делит свои помещения с CIFACOM, школой аудиовизуальных и графических материалов, что дает возможность наладить связи с будущими профессионалами с разными навыками. Кампус Bellevue - это реальное место для междисциплинарных обменов и пространство, отведенное для художественного творчества и появления студенческих проектов!
                </template>
            </Campus>
        </template>
    </CampusMain>

</template>
<script>
import Program from '@/components/schools/slots/Program.vue'
import CampusMain from '@/components/schools/slots/CampusMain.vue'
import SectionsNav from '@/components/schools/slots/SectionsNav.vue'
import Obzor from '@/components/schools/slots/Obzor.vue'
import Campus from '@/components/schools/slots/Campus.vue'

export default {
    data(){
        return {
            currentSection:"presentation",
            program : ""
        }
    },
    components:{
        Program,
        CampusMain,
        SectionsNav,
        Obzor,
        Campus
    },
    methods:{
        showSection(section){
            this.currentSection = section;
            this.desktopScroll();
        },
        desktopScroll(){
            let scrollToElement = document.querySelector(".menu")
            scrollToElement.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }
}
</script>